@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
}

/* Shadcn UI overrides for Material-UI conflicts */
.shadcn-root {
  /* Reset all inherited styles */
  all: initial;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #000;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.shadcn-root * {
  box-sizing: border-box;
}

.shadcn-root table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
  width: 100% !important;
}

.shadcn-root th,
.shadcn-root td {
  border: none !important;
  padding: 16px !important;
  text-align: left !important;
  vertical-align: middle !important;
}

.shadcn-root thead {
  background-color: #f9fafb !important;
}

.shadcn-root thead tr {
  border-bottom: 1px solid #e5e7eb !important;
}

.shadcn-root tbody tr {
  border-bottom: 1px solid #e5e7eb !important;
}

.shadcn-root tbody tr:last-child {
  border-bottom: none !important;
}

.shadcn-root tbody tr:hover {
  background-color: #f3f4f6 !important;
}

.shadcn-root button {
  font-family: inherit !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.shadcn-root .pagination-controls {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 16px !important;
  border-top: 1px solid #e5e7eb !important;
}

/* Override Material-UI styles with higher specificity */
.shadcn-root [class*="MuiTable"],
.shadcn-root [class*="MuiTableHead"],
.shadcn-root [class*="MuiTableBody"],
.shadcn-root [class*="MuiTableRow"],
.shadcn-root [class*="MuiTableCell"] {
  all: unset !important;
  display: revert !important;
  box-sizing: border-box !important;
}

/* Add these additional styles */

/* More specific overrides for Shadcn UI */
.shadcn-table-container {
  /* Force Shadcn styling */
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
}

/* Override any Material-UI table styles */
.shadcn-root table,
.shadcn-root thead,
.shadcn-root tbody,
.shadcn-root tr,
.shadcn-root th,
.shadcn-root td {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

/* Force Shadcn button styling */
.shadcn-root button {
  text-transform: none !important;
  min-width: 0 !important;
  letter-spacing: normal !important;
}

/* Force Shadcn table styling */
.shadcn-root table {
  table-layout: auto !important;
}

/* Force Shadcn header styling */
.shadcn-root thead th {
  font-weight: 500 !important;
  color: #6b7280 !important;
  background-color: #f9fafb !important;
  height: 48px !important;
}

/* Force Shadcn cell styling */
.shadcn-root tbody td {
  padding: 16px !important;
  color: #111827 !important;
}

/* Force Shadcn row hover styling */
.shadcn-root tbody tr:hover {
  background-color: #f3f4f6 !important;
}

/* Dropdown Menu Styles - Working with Tailwind */
.shadcn-root [data-radix-popper-content-wrapper] {
  z-index: 50 !important;
}

/* Style the dropdown container */
.shadcn-root [data-radix-menu-content] {
  background-color: white !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 0.375rem !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
  padding: 0.5rem !important;
  min-width: 12rem !important;
}

/* Style the menu items */
.shadcn-root [role="menuitemcheckbox"] {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  padding: 0.5rem 0.5rem 0.5rem 2rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  color: #374151 !important;
  cursor: pointer !important;
  user-select: none !important;
  border-radius: 0.25rem !important;
  transition: background-color 200ms !important;
}

.shadcn-root [role="menuitemcheckbox"]:hover {
  background-color: #f3f4f6 !important;
}

/* Style the checkbox container */
.shadcn-root [role="menuitemcheckbox"] > span:first-child {
  position: absolute !important;
  left: 0.5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 1rem !important;
  height: 1rem !important;
}

/* Style the checkbox icon */
.shadcn-root [role="menuitemcheckbox"] svg {
  width: 1rem !important;
  height: 1rem !important;
  color: #2563eb !important;
}

/* Override any MUI styles */
.shadcn-root .MuiPopover-root,
.shadcn-root .MuiMenu-root,
.shadcn-root .MuiList-root,
.shadcn-root .MuiMenuItem-root,
.shadcn-root [class*="MuiPopover"],
.shadcn-root [class*="MuiMenu"],
.shadcn-root [class*="MuiList"],
.shadcn-root [class*="MuiMenuItem"] {
  display: none !important;
}

/* Style the Columns button */
.shadcn-root button[aria-haspopup="menu"] {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 2.25rem !important;
  padding: 0 0.75rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #374151 !important;
  background-color: white !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 0.375rem !important;
  cursor: pointer !important;
  transition: background-color 200ms !important;
}

.shadcn-root button[aria-haspopup="menu"]:hover {
  background-color: #f3f4f6 !important;
}

/* Remove the all: unset that was causing issues */
.shadcn-root [role="menu"],
.shadcn-root [data-radix-popper-content-wrapper] > div,
.shadcn-root [role="menuitemcheckbox"],
.shadcn-root [role="menuitemcheckbox"]:hover,
.shadcn-root [role="menuitemcheckbox"] > span,
.shadcn-root [role="menuitemcheckbox"] svg {
  box-sizing: border-box !important;
}

/* Additional dropdown styling fixes */
.shadcn-dropdown {
  isolation: isolate;
  position: relative;
  z-index: 50;
}

.shadcn-dropdown [data-radix-popper-content-wrapper] {
  z-index: 9999 !important;
}

.shadcn-dropdown [role="menuitemcheckbox"] > span {
  position: absolute !important;
  left: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 16px !important;
  height: 16px !important;
}

.shadcn-dropdown [role="menuitemcheckbox"] svg {
  width: 16px !important;
  height: 16px !important;
  color: #2563eb !important;
}

.shadcn-dropdown [role="menuitemcheckbox"]:hover {
  background-color: #f3f4f6 !important;
}

/* Ensure dropdown items use our styles */
.shadcn-dropdown-item {
  width: 100% !important;
  text-align: left !important;
  font-family: inherit !important;
  font-size: 14px !important;
  color: #374151 !important;
  padding: 6px 8px 6px 32px !important;
  margin: 2px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  user-select: none !important;
  background-color: transparent !important;
  transition: background-color 200ms !important;
}

.shadcn-dropdown-item:hover {
  background-color: #f3f4f6 !important;
}

/* Style the checkbox in the dropdown */
.shadcn-dropdown-item > span {
  position: absolute !important;
  left: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 16px !important;
  height: 16px !important;
}

.shadcn-dropdown-item > span svg {
  width: 16px !important;
  height: 16px !important;
  color: #2563eb !important;
}
